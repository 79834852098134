import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import { Grid } from 'semantic-ui-react'

import { Header } from '../../components/Elements'

import HeroSmall from '../../components/HeroSmall'
import CTASection from '../../components/CTA Section'

const ConditionsPage = () => (
  <StaticQuery
  query={graphql`
    query {
      datoCmsHeadersSection(name: { eq: "warunki-uczestnictwa" }) {
        id
        name
        header
        heroImage {
          fixed(width: 900, height: 460) {
          ...GatsbyDatoCmsFixed
          }
          fluid(maxWidth: 1200, maxHeight: 460) {
          ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  `}
  render={data => (
  <Layout>
    <SEO title={ data.datoCmsHeadersSection.name } />
    <HeroSmall data={ data.datoCmsHeadersSection } />
    <Grid centered stackable>
      <Grid.Row columns={2} textAlign='left'>
        <Grid.Column largeScreen={10} computer={12} tablet={12} mobile={12}>
          <Header title='true'>Warunki uczęstnictwa</Header>
        </Grid.Column>
      </Grid.Row>
      <div style={{ minHeight: '60vh'}}></div>
    </Grid>
    <CTASection />
  </Layout>
  )}
/>
)

export default ConditionsPage
